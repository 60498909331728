.movements-main-container {
  display: flex;
  margin-top: 5px;
  gap: 5px;
  height: 100%;
}

.batch-container-wrapper {
  background-color: rgb(34, 34, 34);
  border-radius: 5px;
  height: 100%;
  box-sizing: border-box;
  min-width: 300px;
  border: 1px solid var(--modal-title-border);
}

.movement-group-container-warpper {
  flex-grow: 2;
  background-color: rgb(34, 34, 34);
  border-radius: 5px;
  min-width: 600px;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid var(--modal-title-border);
}

.ticket-container-wrapper {
  background-color: rgb(34, 34, 34);
  border-radius: 5px;
  min-width: 300px;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid var(--modal-title-border);
}

@media only screen and (max-width: 1200px) {
  .batch-container-wrapper,
  .ticket-container-wrapper {
    max-width: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .batch-container-wrapper,
  .ticket-container-wrapper {
    max-width: 350px;
  }
}

@media only screen and (min-width: 1366px) {
  .batch-container-wrapper,
  .ticket-container-wrapper {
    max-width: 400px;
  }
}

.container-component-title {
  font-size: 0.875rem;
  font-weight: bold;
  padding: 8px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  user-select: none;
  background-color: var(--modal-title-bg);
  border-bottom: 1px solid var(--modal-title-border);
  color: var(--modal-title-txt);
}

@media (width < 1200px) {
  .container-component-title {
    font-size: 0.75rem;
  }
}

.movement-group-header-container {
  display: flex;
  color: var(--table-txt);
  padding: 4px 0;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.movement-group-header-container .delivery-id-label-container {
  color: var(--table-txt);
  border-right: 1px solid lightgray;
  padding-right: 5px;
  margin: 0 1.25rem 0 5px;
  white-space: pre;
  position: relative;
}

.movement-group-header-container:hover .copy-btn {
  display: inline-flex;
  cursor: pointer;
  color: darkorange;
}

.movement-detail-container {
  display: grid;
  background-color: #c4c1c1; /* #a09d9d;*/
  color: black;
  gap: 4px;
  padding: 4px;
  grid-template-columns: 24px 96px repeat(11, auto);
  justify-items: center;
  border-top: 1px solid black;
  overflow-x: auto;
}

.movement-detail-container label {
  display: flex;
  align-items: center;
}

.ticket-component {
  border-radius: 5px;
  padding: 5px;
  box-sizing: "border-box";
  border: 1px solid var(--modal-title-border);
}

.movement-aspect-container .tab-content {
  background-color: var(--modal-content-bg);
  padding: 0;
  margin-top: 10px;
  user-select: none;
}

.movement-aspect-container .tab-content-small {
  background-color: var(--modal-content-bg);
  padding: 0;
  margin-top: 10px;
  user-select: none;
}

.movement-aspect-container .tab-content-large .k-animation-container {
  width: 100%;
}

.movement-aspect-container .tab-content-large {
  background-color: var(--modal-content-bg);
  padding: 0;
  margin-top: 10px;
  user-select: none;
  width: 100%;
}

.movement-aspect-container .k-item {
  border: none;
  color: var(--nav-txt);
  margin-right: 1px;
}
.movement-aspect-container .k-item:hover {
  background-color: var(--nav-hover-bg);
  color: var(--nav-txt);
}

.movement-aspect-container .k-item.k-active {
  background-color: var(--nav-selected-bg);
  box-shadow: 0 -3px 0 0 var(--shell-yellow) inset;
  color: var(--nav-txt);
}

/* .movement-aspect-container .k-content.k-active {
  background-color: rgb(28, 31, 32);
  padding: 0;
  border: 0;
  margin: 0;
} */

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  font-size: 0.75rem;
  color: #fff;
  text-align: left;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  margin-left: 0px;
  top: -5px;
  right: 105%;
  /* left: 50%; */
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}

.tooltip .tooltiptext ul {
  background-color: lightyellow;
  color: red;
  padding: 0 0 16px 16px;
  margin: 0;
  list-style-position: inside;
  list-style-type: circle;
  text-align: left;
  border-radius: 7px;
  border-color: lightyellow;
}

.tooltip .tooltiptext li {
  word-spacing: -5px;
  padding: 5px 5px 5px 5px;
  margin: 0;
  background-color: lightyellow;
  text-align: left;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.color-grey {
  color: #dddddc;
}

.color-yellow {
  color: #ffc600;
}

.color-red {
  color: #ff2600;
}

.batch {
  margin-bottom: 5px;
}

.active-movement {
  border-bottom: 4px solid var(--primary-icon-color);
  color: var(--primary-icon-color);
}

.active-movement-group {
  background-color: black;
}

.active-movement-group label {
  display: flex;
  align-items: center;
}

.inactive-movement-group {
  background-color: gray;
}

.inactive-movement-group label {
  display: flex;
  align-items: center;
}

.inactive-movement {
  border-bottom: 4px solid white;
  color: white;
}

.detail-label-wide {
  border: 1px solid #dcd4d4;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  padding: 2px;
}

.detail-label {
  border: 1px solid #dcd4d4;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  padding: 2px;
}

.detail-label-narrow {
  border: 1px solid #dcd4d4;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  padding: 2px;
}

.detail-label-tickets {
  color: black;
  border: 1px solid #dcd4d4;
  margin-right: 5px;
  font-size: 0.75rem;
  width: 100px;
  padding-left: 5px;
}

.prefix-text {
  display: flex;
  align-items: center;
}

.prefix-text-tickets {
  width: 170px;
  color: black;
  text-align: center;
}

.component-title {
  font-size: 1rem;
  color: rgb(231, 231, 231);
  padding: 8px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: black;
}

.background-grey {
  background-color: #dddddc;
}

.background-yellow {
  background-color: #ffc600;
}

.grid-header-label {
  color: "white";
  font-size: "12px";
  padding-top: "3px";
}

.movements-spliter-wrapper {
  background-color: black;
  display: flex;
  padding: 0;
  margin: 0;
  overflow-x: auto;
}

.movements-spliter-ticket-wrap {
  min-width: 200px;
  height: calc(100vh - 300px);
  background-color: rgb(34, 34, 34);
}

.delivery-event-nav-tab {
  width: 33%;
  text-align: center;
  height: 25px;
  font-size: larger;
  padding-top: 10px;
  border-left: 1px solid white;
}

.delivery-event-nav-tab:first-of-type {
  border-left: none;
}

.delivery-event-nav-wrapper {
  display: flex;
  background-color: #1c1f20;
  margin-bottom: 10px;
}

.delivery-event-nav-outter {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.delivery-event-cancel-btn-wrap {
  display: flex;
  padding-left: 300px;
  padding-top: 20px;
}

.delivery-event-cancel-btn {
  width: 70px;
  height: 30px;
}

.delivery-event-btn-wrap {
  display: flex;
  padding-left: 290px;
  padding-top: 20px;
}

.linked-ticket-wrapper {
  width: 400px;
  margin-bottom: 5px;
}

.linked-ticket-outter {
  display: flex;
  background-color: #3c3c3c;
  color: rgb(231, 231, 231);
  margin: 0 10px;
  padding-top: 5px;
}

.linked-ticket-out {
  width: 70%;
  padding-left: 10px;
}

.linked-ticket-cta-wrap {
  float: right;
  width: 30%;
}

.linked-ticket-cta-audit {
  float: right;
  color: var(--primary-icon-color);
  padding: 0 10px 0 3px;
}

.linked-ticket-cta-edit {
  float: right;
  color: #ff6358;
  padding: 0 3px 0 0;
}

.linked-ticket-cta-delink {
  float: right;
  padding: 0 10px 3px 0;
  vertical-align: middle;
  background-color: transparent;
  border: none;
}

.linked-ticket-details-wrap {
  display: flex;
  background-color: #c4c1c1;
  margin: 0 10px;
  padding: 5px 0 5px 10px;
}

.ticket-component-outter {
  display: flex;
  color: var(--modal-title-txt);
  align-items: center;
  gap: 0;
  justify-content: space-between;
}

.ticket-component-label-wrapper {
  padding: 5px;
  display: flex;
  background-color: #c4c1c1;
  gap: 8px;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.availabel-tkt-container-wrapper {
  color: white;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.movement-batch-comp-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
  margin-top: 4px;
  align-self: start;
}

.movement-batch-comp-label-wrapper {
  align-self: center;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-wrap: none;
}

.copy-btn {
  position: absolute;
  display: none;
  right: -30px;
  top: -10px;
  color: darkorange;
}

.movement-batch-comp-wrapper:hover .copy-btn {
  display: inline-flex;
  cursor: pointer;
}

.movement-batch-comp-btn-wrap {
  align-self: start;
  display: flex;
  flex-wrap: nowrap;
}

.movement-batch-container-wrapper {
  height: calc(100% - 40px);
  overflow-y: auto;
  box-sizing: border-box;
  background-color: var(--nav-bg);
}

.movement-grp-wrapper {
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid var(--modal-title-border);
}

.movement-group-label-container {
  display: flex;
  gap: 8px;
  align-items: baseline;
}

.movement-batch-comp-outter {
  color: var(--nav-txt);
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.movement-batch-comp-outter:hover {
  background-color: var(--nav-hover-bg);
}
.movement-batch-comp-outter.selected {
  color: var(--primary-icon-color);
  box-shadow: 0 -3px 0 0 var(--shell-yellow) inset;
  background-color: var(--nav-selected-bg);
}

.movement-debug-info {
  background-color: var(--modal-content-bg);
  line-height: 1.2;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  margin-left: 5px;
  padding-left: 5px;
}
.basic-element-style {
  margin: 0 15px;
  padding: 0;
}
